/***************************************************/
/* HACK TO HAVE THE FOOTER STAY AT THE BOTTOM
/***************************************************/


/**
 * CSSReset.com - How To Keep Footer At Bottom of Page with CSS
 *
 * Original Tutorial: http://www.cssreset.com/2010/css-tutorials/how-to-keep-footer-at-bottom-of-page-with-css/
 * License: Free - do whatever you like with it! Credit and linkbacks much appreciated.
 *
 * NB: Make sure the value for 'padding-bottom' on #content is equal to or greater than the height of #footer.
 */
html {
  height: 100%;
}

body {
  padding-bottom:58px;
  height:100%;
}

#wrapper {
  min-height:100%;
  position:relative;
}

#content {
  padding-bottom:4%; /* Height of the footer element */
}

#footer {
  width:100%;
  height:0px;
  position:absolute;
  bottom:0;
  left:0;
  font-size: 0.8em;
}

