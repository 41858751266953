/***************************************************/
/* RESPONSIVE DESIGN
/***************************************************/


@media only screen and (max-width: 800px) {
    .site-footer {
        height:8em
    }

    .demo-examples:after {
        content: '\A';
    }

}

@media only screen and (min-width: 701px) {
    #drop-button {
        display: none
    }
}


@media only screen and (max-width: 700px) {
    #menu {
        display: none;
        position: absolute;
        top: 4em;
        background: black;
        outline: 1px solid gray;
        z-index: 10;
        width: 70%;
        outline-offset: -1px;

        a {
            width: 100%;
            height: 100%;
            padding: 2em;
            display: block;
            transition: $tran;
        }
    }

    li#home > a {
        font-size: unset;
        height: 100%;
        width: 100%;
        border-radius: unset;
        border: unset;
        text-align: left;
    }

    .grid > img {
        width: 90%;
    }

    .fullscreen-container img {
        height: unset;
    }

    #header ul {
        padding: 0;
    }

    #secret {
        float: unset;
    }

    .zigzag-container {
        display: unset;
    }

    #zigzag {
        width: 90%;
        margin: auto;
        display: block;
    }

    #delim {
        display: none;
    }

    #secret li {
        padding: 0;
        display: unset;
    }

    #header ul li {
        display: flex;
        margin: 0;
        padding: 0;
    }

    #menu > li::after {
        content: "";
    }
    #menu > li::before {
        content: "";
    }

    img.slide {
        height: 25em;
    }

    #menu-items {
        display: inline-block;
    }

    #full-screen-menu {
        display: none;
    }

    .config-content, .dropdown-content {
        max-width: 60%;
        min-width: 60%;
    }

    .config-content {
        left: 39%;
    }

    .dropdown-content {
        right: 39%;
    }

    object.zigzag {
        display: block;
    }
}

@media only screen and (max-width: 500px) {
    .site-footer {
        height:9em
    }
}

@media only screen and (max-width: 400px) {
    span.date:before {
        content: ' ';
        display: block;
    }
}


@media only screen and (max-width: 350px) {
    .site-footer {
        height:10em
    }
}

@media only screen and (max-width: 300px) {
    .site-footer {
        height:11em
    }
}

@media only screen and (max-width: 620px) {
    #header li::after {
        content: none;
    }

    #header li::before {
        content: none;
    }

}

@media screen and (max-width: 740px) {
    div.content {margin-left: 0;}

    li.quote {
        white-space: pre-line;
    }

    .fixed {
        width: 100%;
        margin-right: 0.5em;
        margin-left: 0.5em;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    #content {
        padding: 4%;
    }

    div.about {
        width: 100%;
        background-image: none;
    }

    div.writings {
        width: 100%;
        background-image: none;
    }

    div.code {
        width: 100%;
        background-image: none;
    }

    div.git {
        width: 100%;
        background-image: none;
    }

    div.favorites {
        width: 100%;
        background-image: none;
    }

    h1 {
        font-size: 2.5em;
    }

    img.center {
        width: 70%;
        padding: 5%;
    }


    ul.code, li.code {
        display: inherit;
    }
}


ul .icon-bar {
    margin-top: .375em;
    margin-bottom: .375em;
    display: block;
    margin-right: auto;
    margin-left: 20px;
    width: 2em;
    height: 0.3em;
    background-color: white;
}

